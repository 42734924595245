import React from 'react';
import './How_it_works.css';
import question_mark from '../assets/question_mark.svg'
import connect from '../assets/connect.svg'
import all_agents from '../assets/all_agents.svg'
import live from '../assets/live.svg'



const HowItWorksSection = () => {
    return (
        <div className="howitworks">
            <img src={question_mark} alt="Question Icon" width="64" height="64" />
            <h2>How It Works</h2>
            <p>Getting started with your AI agent is very simple.</p>
            <div className="steps">
                <div className="step">
                    <img src={connect} alt="Step 1" width="172" height="172" />
                    <h3>Connect with our team</h3>
                    <p>Schedule a call with our experts to discuss your business needs.</p>
                </div>
                <div className="step">
                    <img src={all_agents} alt="Step 2" width="172" height="172" />
                    <h3>Choose your AI Agent</h3>
                    <p>We recommend the best-fit solution for your customer support.</p>
                </div>
                <div className="step">
                    <img src={live} alt="Step 3" width="172" height="172" />
                    <h3>Start automating</h3>
                    <p>Access the dashboard and watch your agent handle customer support easily.</p>
                </div>
            </div>
        </div>
    );
};

export default HowItWorksSection;




