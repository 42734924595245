import React ,{useEffect, useState} from 'react';
import './Customer_queries.css';
import product_query from '../assets/product_query.svg';
import general_query from '../assets/general_query.svg';
import order_query from '../assets/order_query.svg';
import check from '../assets/smart_secret/tick_mark.svg'
const QueriesFirst = () => (
  <div className="query_header">
    <h1 className="title">Customer Queries? <br />Our Agents Handle Them All</h1>
    <p className="subtitle">Our AI Agents are well trained to handle a variety of customer support use cases.</p>
  </div>
);

const ChatCard = ({ imageSrc }) => (
  <div className="chat-card">
    <img src={imageSrc} alt="Query Icon" />
  </div>
);

const InfoCard = ({ title, description, features = [] }) => (
  <div className="info-card">
    <h2 className="info-card__title">{title}</h2>
    <p className="info-card__description">{description}</p>
    <div className="info-card__features">
      {features.map((feature, index) => (
        <div key={index} className="info-card__feature">
          <div className="info-card__feature-icon">
            <img
              src={check}
              alt="check"
              width="20"
              height="20"
            />
          </div>
          <p className="info-card__feature-text">{feature.text}</p>
        </div>
      ))}
    </div>
    <button className="info-card__button" onClick={() => window.open('https://calendly.com/krunk-ai/30min?', '_blank')}
  style={{ cursor: 'pointer' }}>
      <span className="info-card__button-text" >Schedule a Call</span>
    </button>
  </div>
);

const MobileInfoCard = ({ title, description, features = [], image }) => (
  <div className="info-card" style={{ backgroundColor: "#f0f4ff", padding: "20px", borderRadius: "8px" }}>
    <h2 className="info-card__title">{title}</h2>
    <img className="info-card__image" src={image}  alt="Query Icon" />
    <p className="info-card__description">{description}</p>
    <div className="info-card__features">
      {features.map((feature, index) => (
        <div key={index} className="info-card__feature">
          <div className="info-card__feature-icon">
            <img
              src={check}
              alt="check"
              width="20"
              height="20"
            />
          </div>
          <p className="info-card__feature-text">{feature.text}</p>
        </div>
      ))}
    </div>
    <button className="info-card__button" onClick={() => window.open('https://calendly.com/krunk-ai/30min?', '_blank')}
  style={{ cursor: 'pointer' }}>
      <span className="info-card__button-text">Schedule a Call</span>
    </button>
  </div>
);

// InfoCard.defaultProps = {
//   title: "Post Order Queries",
//   description: "Keep customers in the loop at every step.",
//   features: [
//     {
   
//       text: "Order status checks",
//     },
//     {
     
//       text: "Handling of cancellation, return, and exchange requests autonomously.",
//     },
//   ],
//   buttonText: "Schedule a Call",
// };



const CustomerQueries = () => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

   const rendercontent = () =>{
    return  <div className="customerqueries">
      <QueriesFirst />
      <div className="content">
        <div className="card-container">
          <ChatCard imageSrc={order_query} />
          <InfoCard 
          title={"Post Order Queries"} description={"Keep customers in the loop at every step."}
          features = {[
            {
             
              text: "Order status checks",
            },
            {
            
              text: "Handling of cancellation, return, and exchange requests autonomously.",
            },
          ]}
          /> 
        </div>
        <div className="card-container">
        <InfoCard 
            title={"Product Inquiries"} description={"Offer product recommendations, upsell options, and provide detailed information—all automatically."}
            features = {[
              {
               
                text: "Product sugeestion based on user preference",
              },
              {
               
                text: "Products upselling for comlementary items.",
              },
              {
                text: "Detailed Product information inquiries.",}
            ]}/>
          <ChatCard imageSrc={product_query} />
          
        </div>
  
        <div className="card-container">
          <ChatCard imageSrc={general_query} />
          <InfoCard     title={"General Business Information"} description={"Making business information a lot easier to access for both ou and your customer."}
          features = {[
            {
             
              text: "All business related queries, including policies, services and more",
            },
           
          ]}/>
        </div>
  
  
      </div>
    </div>;
   }
  

   const mobilerenderContent = () =>{
    return  <div className="customerqueries">
    <QueriesFirst />
    <div className="content">
      
       
        <MobileInfoCard 
        title={"Post Order Queries"} description={"Keep customers in the loop at every step."}
        features = {[
          {
           
            text: "Order status checks",
          },
          {
          
            text: "Handling of cancellation, return, and exchange requests autonomously.",
          },
        ]}
        image={order_query}
        /> 
    
    
      <MobileInfoCard 
          title={"Product Inquiries"} description={"Offer product recommendations, upsell options, and provide detailed information—all automatically."}
          features = {[
            {
             
              text: "Product sugeestion based on user preference",
            },
            {
             
              text: "Products upselling for comlementary items.",
            },
            {
              text: "Detailed Product information inquiries.",}
          ]}
          image = {product_query}
          />
     
        
     

    
    
        <MobileInfoCard     title={"General Business Information"} description={"Making business information a lot easier to access for both ou and your customer."}
        features = {[
          {
           
            text: "All business related queries, including policies, services and more",
          },
         
        ]}
        image={general_query}/>
      


    </div>
  </div>;
   }
  return (
    <div>
{!isMobile && rendercontent() } 
    {isMobile && mobilerenderContent()
    }
    </div>
    
 )
};

export default CustomerQueries;
