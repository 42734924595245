import React from "react";

const PrivacyPolicy = () => {
  const styles = {
    container: {
      maxWidth: "72rem",
      margin: "5rem auto",
      padding: "2.5rem",
      fontSize: "0.875rem",
      lineHeight: "1.6",
    },
    heading: {
      fontSize: "2rem",
      fontWeight: "bold",
      paddingBottom: "1rem",
    },
    subHeading: {
      fontSize: "1.5rem",
      fontWeight: "600",
      paddingTop: "1rem",
    },
    sectionHeading: {
      fontSize: "1.25rem",
      fontWeight: "500",
      paddingTop: "0.75rem",
    },
    paragraph: {
      marginTop: "0.5rem",
    },
    list: {
      paddingLeft: "1.5rem",
    },
    listItem: {
      marginTop: "0.5rem",
    },
    italic: {
      fontStyle: "italic",
    },
    bold: {
      fontWeight: "bold",
    },
  };

  return (
    <section style={styles.container}>
      <h2 style={styles.heading}>Privacy Policy</h2>
      <p style={styles.paragraph}>
        Our Privacy Policy governs how we protect your privacy and handle your
        personal information when you use this Website, the Platform, or the
        Services. You agree that your use of this Website, the Platform, and the
        Services indicates your acceptance to the processing of your personal
        information in accordance with our Privacy Policy.
      </p>

      {/* More sections below, follow the same styling */}
      <h2 style={styles.subHeading}>VOICE AND TEXT SERVICES</h2>
      <p style={styles.paragraph}>
        a. In connection with outbound calling and sending short message
        services (“SMS”) /multimedia messaging messages (“MMS”) through the
        Services (“Voice and Text Services”), krunk.ai disclaims any liability
        with regards to (i) third-party wireless service providers (“Carriers”)
        or (ii) third-party intermediaries that transmit calls and SMS/MMS
        messages between Providers and Carriers. In addition, Carriers and/or
        Aggregators may charge additional per-message fees for SMS/MMS messages.
        krunk.ai will invoice Customers monthly in arrears for such fees, if
        any.
      </p>

      <h2 style={styles.subHeading}>Links to Third-Party Sites</h2>
      <p style={styles.paragraph}>
        This Website may contain links to websites owned and operated by third
        parties. These links are provided for informational purposes only. The
        linked sites are not under the control of krunk.ai and krunk.ai is not
        responsible for the contents of any linked sites.
      </p>

      <h2 style={styles.subHeading}>Confidentiality</h2>
      <p style={styles.paragraph}>
        From time to time, either party (the “Disclosing Party”) may disclose or
        make available to the other party (the “Receiving Party”) non-public,
        proprietary, and confidential information of the Disclosing Party
        (“Confidential Information”). Confidential Information includes any
        information that reasonably should be understood to be confidential
        given the nature of the information and the circumstances of disclosure.
      </p>

      <h2 style={styles.subHeading}>Limitation of Liabilities</h2>
      <p style={styles.paragraph}>
        IN NO EVENT SHALL krunk.AI, ITS AFFILIATES, AND THEIR RESPECTIVE
        OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS, OR LICENSORS BE LIABLE FOR ANY
        DAMAGES ARISING OUT OF, OR IN CONNECTION WITH, THE USE OR PERFORMANCE OF
        THE SERVICES.
      </p>

      <h2 style={styles.subHeading}>Governing Law, Arbitration and Jurisdiction</h2>
      <h4 style={styles.sectionHeading}>Governing Law and Jurisdiction</h4>
      <p style={styles.paragraph}>
        For Customer residing in:
        <ul style={styles.list}>
          <li style={styles.listItem}>
            INDIA: All matters relating to these Terms, your access to or use of
            this Website or the Services are governed by the laws of India,
            without regard to its conflict of law provisions.
          </li>
          <li style={styles.listItem}>
            SINGAPORE: All matters relating to these Terms, your access to or
            use of this Website or the Services are governed by the laws of
            Singapore.
          </li>
        </ul>
      </p>

      <h4 style={styles.sectionHeading}>Arbitration</h4>
      <p style={styles.paragraph}>
        You and krunk.ai agree to resolve any past or present claims relating to
        these Terms or our Services through final and binding arbitration.
      </p>

      <h4 style={styles.sectionHeading}>Class Action Waiver</h4>
      <p style={styles.paragraph}>
        Disputes must be brought on an individual basis only, and may not be
        brought as a plaintiff or class member in any purported class,
        consolidated, or representative proceeding.
      </p>

      <h4 style={styles.sectionHeading}>Entire Agreement</h4>
      <p style={styles.paragraph}>
        Unless you and krunk.ai enter into a separate valid subscription
        agreement or similar service agreement, these Terms constitute the
        entire and exclusive agreement between you and krunk.ai.
      </p>

      <h2 style={styles.subHeading}>Corrections and Changes</h2>
      <p style={styles.paragraph}>
        While we will endeavor to keep this Website up to date, krunk.ai cannot
        assume responsibility for any errors or omissions in the materials made
        available on this Website or through the Services.
      </p>
    </section>
  );
};

export default PrivacyPolicy;
