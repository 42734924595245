import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container mt-20 py-10 text-sm max-w-6xl leading-6 space-y-4 TandCPolicy">
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold py-4 sm:py-8">
        Terms & Conditions
      </h2>
      <h2 className="text-2xl font-semibold">Account Registration</h2>
      <p>
        If any of our Services require you to create an account, you must
        complete the registration process by providing us with current,
        complete, and accurate information as prompted by the applicable
        registration form or you may login through a third-party processor such
        as your Google account. In the latter case, personal information you
        provided to that third party, such as your name, email address, and
        other information, your privacy settings on that service allow us to
        access, will be used to create your krunk.ai account. Your member
        account will include a username and password of your choosing.
      </p>
      <p>
        You are responsible for maintaining the confidentiality of your password
        and account information. Furthermore, you are solely responsible for all
        activity that occurs under your account. If you notice any unusual
        activities or suspect unauthorized activity on your account or any other
        breach of security, you must immediately notify us at infosec@krunk.ai.
        krunk.ai will not be liable for any loss that you may incur as a result
        of someone else using your account or password, either with or without
        your knowledge. However, you could be held liable for losses incurred by
        krunk.ai or our suppliers due to someone else using your account or
        password. You may not use anyone else’s account at any time, without the
        permission of the account holder.
      </p>
      <p>
        We reserve the right to terminate unpaid user accounts and accounts that
        are inactive for more than 90 days. In the event of such termination,
        all data associated with such a user account will be deleted. We will
        provide you prior notice of such termination and option to back-up your
        data. This data deletion policy applies to all Services.
      </p>
      <h2 className="text-2xl font-semibold">Your Content/Customer Data</h2>
      <p>
        By using the Services, you may provide us with text, graphics, music,
        audio, video, works of authorship of any kind, and information or other
        materials that are posted, generated, provided or otherwise made
        available through the Services or images, file attachments, and other
        information (“your content”). You retain full ownership of your content.
        You can remove your content by deleting it. However, in certain
        instances, some of your content which may be posted as reviews or
        testimonials or when your data is shared with a third-party partner or
        service provider, may not be completely removed. We are not responsible
        or liable for the removal or deletion of any of your content, or the
        failure to remove or delete such content.
      </p>
      <p>
        By making your content available through Services, you hereby grant to
        krunk.ai a non-exclusive, worldwide, perpetual, irrevocable,
        royalty-free, transferable license, with the right to grant sublicenses,
        to use, reproduce, modify, create derivative works based upon, publicly
        display, publicly perform and distribute copies of your content in
        connection with operating and providing the Services.
      </p>
      <p>
        You are solely responsible for your content. You represent and warrant
        that t you own or have the necessary rights to all of your content, and
        that use of your content by krunk.ai on or through the Services will not
        infringe, misappropriate or violate a third party’s intellectual
        property rights, or rights of publicity or privacy, or result in the
        violation of any applicable law or regulation.
      </p>
      <h2 className="text-2xl font-semibold">Services</h2>
      <p>
        As part of the Services, we provide you with access to a variety of
        resources, including documentation and other product information about
        the Services, download areas and other web services, software, including
        developer tools and sample code, training courses and other learning
        materials, and Application Program Interfaces (“APIs”). Your access and
        use of the Services, including any updates, enhancements, and new
        features we may provide, are subject to these Terms unless we have
        provided certain products or services to you under more specific terms,
        in which case, those more specific terms will apply.
      </p>
      <h2 className="text-2xl font-semibold">Software</h2>
      <p>
        Unless you have a valid agreement with krunk.ai that entitles you to do
        so, you may not copy, reproduce, resell, sublicense, or redistribute any
        software made available to you. For your convenience, we may make
        available various tools and utilities for you to download and use as
        part of the Services.
      </p>
      <p>
        Some of the tools and utilities provided as part of the Services or made
        available for download use artificial intelligence and machine learning
        (collectively, “AI”) to allow you, your employees and agents, or your
        customers and other end-users to submit text and other materials (a
        “Prompt”) and receive AI-generated results (“Output”) via various
        interfaces including APIs, chat bots, and web portals (collectively, the
        “GenAI Services”). Some of the GenAI Services may be integrated into
        your application or software environments through interfaces that allow
        users to submit Prompts and receive Outputs. We are constantly working
        to improve the AI features of our Services to make them more accurate,
        reliable, safe, and beneficial. Given the probabilistic nature of AI,
        use of the GenAI Services may result in incorrect Output that does not
        accurately reflect real people, places, or facts. The GenAI Services may
        generate responses by reading a user’s request and then predicting the
        next most likely words that might appear in response. In some cases, the
        most likely next words may not be the most factually accurate ones. For
        these reasons, you should not rely on the factual accuracy of Output
        from the GenAI Services. You should evaluate the accuracy of any Output
        as appropriate for your use case, including by using human review of the
        Output. Where human review is not possible or practical, you are
        responsible for providing appropriate notice to your users, including
        end users accessing AI through your platform, that is sufficient for
        them to understand the limitations of any AI features being used,
        including, but not limited to, that users should not rely on factual
        assertions in Outputs without independently fact checking their accuracy
        and that users may be exposed to content they may find offensive,
        inappropriate, or objectionable. You are solely responsible for the
        actions you take using the GenAI Services, including for ensuring that
        your use of the Output does not violate any applicable law or these
        Terms. You agree that you use the GenAI Services at your own risk.
      </p>
      <p>
        We do not make any assurances with regard to the accuracy of the results
        or output, including the Output from the GenAI Services, that derives
        from such use of any such tools and utilities.
      </p>
      <h2 className="text-2xl font-semibold">Restrictions</h2>
      <p>
        The Services, including this Website, the krunk.ai Platform and related
        technology, and the krunk.ai software, documentation, APIs, training
        materials, and other content, is protected by the applicable national
        and international copyright, trademark, patent, trade secret, and other
        intellectual property laws and proprietary rights. krunk.ai reserves all
        rights not expressly granted under these Terms, and no other rights are
        granted by implication, estoppel, or otherwise. You may only use the
        Platform and Services as permitted by law, including all applicable
        international, federal, state, and local laws and regulations. We
        reserve the right to investigate violations of these Terms and may also
        consult and cooperate with law enforcement authorities to prosecute
        users who violate the law.
      </p>
      <p>
        You may not use the Platform or Services for any purpose that is
        unlawful or prohibited by these Terms. You may not use the Platform or
        Services in any manner that could damage, disable, overburden, or impair
        any krunk.ai Platform or Services, or interfere with any other party’s
        use and enjoyment of the Platform or Services. You may not attempt to
        gain unauthorized access to the Platform or Services, or other accounts,
        computing systems, or networks and applications, through hacking,
        password mining, or any other means.
      </p>
      <p>Do not, for example:</p>
      <ul className="text-lg space-y-2">
        <li>
          Use any engine, software, tool, agent, device, mechanism or the like
          to access, search, download, or use the Platform or Services in any
          way other than through our publicly supported interfaces;
        </li>
        <li>
          Access, tamper with, or use non-public areas of this Website, the
          Platform, or the technical delivery systems of our service providers;
        </li>
        <li>
          Probe, scan, or test the vulnerability of any krunk.ai system or
          network or breach any security or authentication measures;
        </li>
        <li>
          Decipher, decompile, disassemble, or reverse engineer the Platform or
          any of the software used to provide this Website or the Services;
        </li>
        <li>
          Plant malware or use the Platform or Services to distribute malware;
        </li>
        <li>
          Violate the privacy of any third parties, including employees,
          consultants, service providers, of other users of the Services;
        </li>
        <li>Violate any applicable law or regulation;</li>
        <li>
          Impersonate or misrepresent your affiliation with any person or
          entity, or post or transmit anything that is fraudulent or misleading;
          or
        </li>
        <li>
          Attempt any of the above, or encourage or enable any third party to do
          any of the above.
        </li>
        <li>
          send or store infringing, unsolicited, spam, offensive, obscene,
          threatening, harmful, libelous, abusive or which otherwise is of
          criminal or unethical nature according to applicable law.
        </li>
      </ul>
      <p>
        Some of the Services may contain bulletin boards, chat areas, user
        groups, forums, communities, personal webpages, calendars, photo albums,
        file cabinets, and email or other message or communication facilities
        designed to enable you to communicate with others (“Communication
        Services”). You agree to use the Communication Services only to post,
        send, and receive messages and material that are proper and, when
        applicable, related to the particular Communication Service. When using
        the Communication Services, you may not:
      </p>
      <ul className="text-lg space-y-2">
        <li>
          Use the Communication Services in connection with surveys, contests,
          pyramid schemes, chain letters, junk email, spamming, or any
          duplicative or unsolicited messaging (commercial or otherwise);
        </li>
        <li>
          Send unsolicited communications, promotions, advertisements, or spam;
        </li>
        <li>
          Defame, abuse, harass, stalk, threaten, or otherwise violate the legal
          rights (such as rights of privacy and publicity) of others;
        </li>
        <li>
          Publish, post, upload, distribute, or disseminate any offensive,
          inappropriate, profane, defamatory, obscene, indecent, or unlawful
          topic, material, or information;
        </li>
        <li>
          Upload, or otherwise make available, files that contain images,
          photographs, software, or other material protected by intellectual
          property laws, unless you own the rights thereto or have received all
          necessary consent to do the same;
        </li>
        <li>
          Use any material or information, including images or photographs,
          which are made available through the Website or Communication Services
          in any manner that infringes any copyright, trademark, patent, trade
          secret, or other proprietary right of any party;
        </li>
        <li>
          Upload files that contain viruses, Trojan horses, worms, time bombs,
          cancel bots, corrupted files, or any other similar software or
          programs that may damage the operation of another’s property;
        </li>
        <li>
          Advertise or offer to sell or buy any goods or services for any
          business purpose, unless such Communication Services specifically
          allows such messages;
        </li>
        <li>
          Download any file posted by another user of the Communication Services
          that you know, or reasonably should know, cannot be legally
          reproduced, displayed, performed, or distributed;
        </li>
        <li>
          Falsify or delete any copyright management information, such as author
          attributions, legal or other notices, or proprietary designations or
          labels of the origin or source of software or other material contained
          in a file that is uploaded;
        </li>
        <li>
          Restrict or inhibit any other user from using and enjoying the
          Communication Services;
        </li>
        <li>
          Violate any code of conduct or other guidelines which may be
          applicable to the Communication Services;
        </li>
        <li>
          Harvest, mine, or otherwise collect information about others,
          including email addresses and any other personally identifiable
          information;
        </li>
        <li>Violate any applicable laws or regulations;</li>
        <li>
          Create a false identity for the purpose of misleading others; or
        </li>
        <li>
          Use, download, copy, or provide (whether or not for a fee) any
          directory of users of the Services or other user or usage information.
          krunk.ai has no obligation to monitor the Communication Services.
          However, we reserve the right to review materials sent or posted using
          the Communication Services and to remove any materials at our sole
          discretion. Furthermore, we reserve the right to terminate your access
          to any or all of the Communication Services at any time, without
          notice, in case of violation of the above. We reserve the right at all
          times to disclose any information deemed necessary to satisfy any
          applicable law, regulation, legal process, or governmental request, or
          to edit, refuse to post, or to remove any information or materials, in
          whole or in part, at our sole discretion. Always use caution when
          providing any personally identifiable information in the Communication
          Services. krunk.ai does not control or endorse the content, messages,
          or information in the Communication Services and specifically
          disclaims any liability with regard to the Communication Services and
          any actions resulting from your use of or participation in the
          Communication Services. Materials uploaded or posted to the
          Communication Services may be subject to limitations on usage,
          reproduction, or dissemination. You are responsible for adhering to
          any such limitations if you download any such materials.
        </li>
      </ul>
      <h2 className="text-2xl font-semibold">Violation of Terms of Service</h2>
      <p>
        We reserve the right to terminate your user account or otherwise suspend
        use of or access to the Services if you are found in violation of these
        Terms. Further, we reserve the right to pursue any and all legal and
        equitable remedies against you for such violations.
      </p>
    </div>
  );
};

export default TermsAndConditions;
