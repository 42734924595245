import React from 'react';
import './Take_them_for_spin.css';
import webicon from '../assets/webicon.svg'
import callicon from '../assets/phone.svg'
import whatsapp from '../assets/smart_secret/whatsapp.svg'
const Component = () => {
  return (
    <div className="spin">
      <h1 className="title">Take ‘em For A Spin</h1>
      <p className="subtitle">See our AI agents in action and experience seamless support in real time.<br />Try our sample agents now!</p>
      <div className="spin-container">
        <div className="spin-card"  onClick={() => window.open('https://demo.krunk.ai', '_blank')}
  style={{ cursor: 'pointer' }}>
          <img src={webicon} alt="Web AI Chatbot" width="64" height="64" />
          <p className="card-title"  onClick={() => window.open('https://demo.krunk.ai', '_blank')}
  style={{ cursor: 'pointer' }}>Web AI Salesbot</p>
        </div>
        <div className="spin-card"  onClick={() => window.open('https://demo.krunk.ai', '_blank')}
  style={{ cursor: 'pointer' }}>
          <img src={whatsapp} alt="AI Call Handler" width="64" height="64" />
          <p className="card-title" >WhatsApp Salesbot</p>
        </div>
      </div>
    </div>
  );
};

export default Component;

