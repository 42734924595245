import React from "react";

const CancellationAndRefundPolicy = () => {
  const styles = {
    container: {
      maxWidth: "72rem",
      margin: "5rem auto",
      padding: "2.5rem",
      fontSize: "0.875rem",
      lineHeight: "1.5",
    },
    heading: {
      fontSize: "2rem",
      fontWeight: "bold",
      paddingBottom: "1rem",
    },
    subHeading: {
      fontSize: "1.5rem",
      fontWeight: "600",
      paddingTop: "1rem",
    },
    sectionHeading: {
      fontSize: "1.25rem",
      fontWeight: "500",
      paddingTop: "0.75rem",
    },
    paragraph: {
      marginTop: "0.5rem",
    },
    contactInfo: {
      marginTop: "1rem",
    },
  };

  return (
    <section style={styles.container}>
      <h2 style={styles.heading}>Cancellation and Refund Policy</h2>
      <h2 style={styles.subHeading}>1. Introduction</h2>
      <p style={styles.paragraph}>
        At Krunk Technologies Private Limited, we strive to provide the best
        service possible. However, we understand that there may be instances
        where you need to cancel your subscription or request a refund. This
        policy outlines the terms and conditions regarding cancellations and
        refunds for our services.
      </p>
      <h2 style={styles.subHeading}>2. Cancellation Policy</h2>
      <div>
        <h3 style={styles.sectionHeading}>2.1 Subscription Cancellation</h3>
        <p style={styles.paragraph}>
          Customers can cancel their subscription at any time through their
          account settings or by contacting our support team at support@krunk.ai.
          Cancellations will take effect at the end of the current billing
          cycle. You will continue to have access to the service until the end
          of the paid period. We do not offer prorated refunds for cancellations
          made before the end of the billing cycle.
        </p>
      </div>
      <div>
        <h3 style={styles.sectionHeading}>2.2 Free Trial Cancellation</h3>
        <p style={styles.paragraph}>
          If you are on a free trial, you can cancel at any time during the
          trial period without any charges. <br />
          If you do not cancel before the trial period ends, your subscription
          will automatically convert to a paid plan, and you will be charged
          according to the terms of the plan you selected.
        </p>
      </div>
      <h2 style={styles.subHeading}>3. Refund Policy</h2>
      <div>
        <h3 style={styles.sectionHeading}>3.1 Eligibility for Refunds</h3>
        <p style={styles.paragraph}>
          Refunds are typically only issued for technical issues that prevent
          you from using the service, which cannot be resolved by our support
          team. Refund requests must be made within 5 days of the initial
          purchase or renewal date.
        </p>
      </div>
      <div>
        <h3 style={styles.sectionHeading}>3.2 Non-Refundable Cases</h3>
        <p style={styles.paragraph}>
          Refunds will not be issued for lack of usage or dissatisfaction with
          the service if the service was provided as described. We do not
          provide refunds for any unused portion of a subscription term.
        </p>
      </div>
      <div>
        <h3 style={styles.sectionHeading}>3.3 Requesting a Refund</h3>
        <p style={styles.paragraph}>
          To request a refund, contact our support team at support@krunk.ai with
          your account details and the reason for the request. Our team will
          review your request and notify you of the approval or rejection of
          your refund.
        </p>
      </div>
      <h2 style={styles.subHeading}>4. Changes to This Policy</h2>
      <p style={styles.paragraph}>
        We may update our Cancellation and Refund Policy from time to time. We
        will notify you of any changes by posting the new policy on our website.
        You are advised to review this policy periodically for any changes.
        Changes to this policy are effective when they are posted on this page.
      </p>
      <h2 style={styles.subHeading}>5. Contact Us</h2>
      <p style={styles.contactInfo}>
        If you have any questions about our Cancellation and Refund Policy,
        please contact us: <br />
        Email: support@krunk.ai <br />
        Address: 509/170 new tg civil lines dr bnn road, new hyderabad, Lucknow.
        <br />
        Thank you for choosing Krunk Technologies Private Limited. We
        appreciate your business and are here to help ensure you have the best
        experience possible.
      </p>
    </section>
  );
};

export default CancellationAndRefundPolicy;
