import React, {useState, useEffect} from 'react';
import './LogOff.css';

const LogOffComponent = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const rendermobile = () =>{
    return   <div className="logoff">
      <div className="image-section">
      <img src="https://dashboard.codeparrot.ai/api/assets/Z1asTe4ZL_Fz8l1p" alt="AI Support" width="555" height="248" />
    </div>
    <div className="text-section">
      <h1>Your AI Support: Always On, So You Can Log Off</h1>
      <p>With our AI agents working around the clock, your customer support is in good hands. Sit back and watch your business grow without lifting a finger.</p>
      <div className="buttons">
        <button className="schedule-button" onClick={() => window.open('https://calendly.com/krunk-ai/30min?', '_blank')}
  style={{ cursor: 'pointer' }}>Schedule a Call</button>
        <button className="demo-button" onClick={() => window.open('https://demo.krunk.ai', '_blank')}
  style={{ cursor: 'pointer' }}>Try Demo</button>
      </div>
    </div>
    
  </div>
  }

  const renderweb = () =>{
    return   <div className="logoff">
    <div className="text-section">
      <h1>Your AI Support: Always On, So You Can Log Off</h1>
      <p>With our AI agents working around the clock, your customer support is in good hands. Sit back and watch your business grow without lifting a finger.</p>
      <div className="buttons">
        <button className="schedule-button" onClick={() => window.open('https://calendly.com/krunk-ai/30min?', '_blank')}
  style={{ cursor: 'pointer' }}>Schedule a Call</button>
        <button className="demo-button" onClick={() => window.open('https://demo.krunk.ai', '_blank')}
  style={{ cursor: 'pointer' }}>Try Demo</button>
      </div>
    </div>
    <div className="image-section">
      <img src="https://dashboard.codeparrot.ai/api/assets/Z1asTe4ZL_Fz8l1p" alt="AI Support" width="555" height="248" />
    </div>
  </div>
  }

  return (
    <div >
    {!isMobile && renderweb()}
    {isMobile && rendermobile()}
    </div>
  );
};

export default LogOffComponent;

