// Footer.js
import React, { useState } from "react";
import { toast } from "react-toastify";
import "./Footer.css";

const Connect = [
  {
    name: "Call: +917460010213",
    path: "tel:+917460010213",
  },
  {
    name: "Contact: support@krunk.ai",
    path: "mailto:support@krunk.ai",
  },
];

const Help = [
  {
    name: "FAQ",
    path: "#", // Replace with your FAQ URL if available
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
  },
  {
    name: "Terms & Conditions",
    path: "/terms&conditions",
  },
  {
    name: "Cancellation & Refund Policy",
    path: "/cancellation&refund-policy",
  },
  {
    name: "Shipping Policy",
    path: "/shipping-policy",
  },
];

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubscription = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Email required");
      return;
    }
    toast.success("Subscribed");
    setEmail(""); // Clear email input after subscription
  };

  return (
    <div className="footer-container">
      <div className="footer-content">
        <main className="footer-main-section">
          <div className="footer-column">
            <h1 className="footer-heading">Krunk.ai</h1>
          </div>
          <div className="footer-column">
            <h1 className="footer-heading">Connect</h1>
            <div className="footer-links">
              {Connect.map((item, i) => (
                <a href={item.path} key={i} className="footer-link">
                  {item.name}
                </a>
              ))}
              <p className="footer-address">
                Mailing Address: H No.68, First Floor, Krishna Kunj Colony,
                Aurangabad, Mathura, Mathura- 281006, Uttar Pradesh
              </p>
            </div>
          </div>
        </main>
        <main className="footer-main-section">
          <div className="footer-column">
            <h1 className="footer-heading">Help</h1>
            <div className="footer-links">
              {Help.map((item, i) => (
                <a href={item.path} key={i} className="footer-link">
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <form onSubmit={handleSubscription} className="footer-newsletter">
            <h1 className="footer-heading">Our Newsletter</h1>
            <p className="newsletter-subtitle">
              Stay informed about the latest trends:
            </p>
            <div className="newsletter-input-container">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter Email ID"
                className="newsletter-input"
              />
            </div>
            <button type="submit" className="newsletter-subscribe-btn">
              Subscribe
            </button>
          </form>
        </main>
      </div>
    </div>
  );
};

export default Footer;
