import React from 'react';
import './Hero_section.css';


import meditation2 from '../assets/Group 6356092.svg'




const HeroSection = () => {
  return (
    <div className="herosection">
      <div className="text-container">
        <h1>Increased Revenue, With Zero Hassle</h1>
        <p>
        Boost your revenue effortlessly as our AI agents handle queries to resolutions across web, WhatsApp, and calls, ensuring every customer interaction turns into a growth opportunity
        </p>
      </div>
      <div className="cta-container">
      <a 
          className="schedule" 
          href="https://calendly.com/krunk-ai/30min" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Schedule a Call
        </a>

        <a 
          className="demo" 
          href="https://calendly.com/krunk-ai/30min" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Try Demo
        </a>
       
      </div>
        <img
          src={meditation2}
         className="illustration-container"
        /> 
    </div>
  );
};

export default HeroSection;
