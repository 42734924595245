import React from "react";
import { Link } from "react-router-dom";

const ShippingPolicy = () => {
  const styles = {
    container: {
      maxWidth: "72rem",
      margin: "5rem auto",
      padding: "2.5rem",
      fontSize: "0.875rem",
      lineHeight: "1.6",
    },
    heading: {
      fontSize: "2rem",
      fontWeight: "bold",
      paddingBottom: "1rem",
    },
    subHeading: {
      fontSize: "1.5rem",
      fontWeight: "600",
      paddingTop: "1rem",
    },
    sectionHeading: {
      fontSize: "1.25rem",
      fontWeight: "500",
      paddingTop: "0.75rem",
    },
    paragraph: {
      marginTop: "0.5rem",
    },
    link: {
      color: "#1a73e8",
      textDecoration: "none",
    },
  };

  return (
    <section style={styles.container}>
      <h2 style={styles.heading}>Shipping and Delivery Instructions</h2>
      <h2 style={styles.subHeading}>1. Introduction</h2>
      <p style={styles.paragraph}>
        Welcome to Krunk Technologies Private Limited. Since our products are
        digital, there are no physical shipping requirements. This page provides
        information on how to access and start using our services after your
        purchase.
      </p>
      <h2 style={styles.subHeading}>2. Order Processing</h2>
      <div>
        <h3 style={styles.sectionHeading}>2.1 Confirmation Email</h3>
        <p style={styles.paragraph}>
          After completing your purchase, you will receive an order confirmation
          email at the email address provided during checkout. This email will
          include details of your purchase and a receipt.
        </p>
      </div>
      <div>
        <h3 style={styles.sectionHeading}>2.2 Account Activation</h3>
        <p style={styles.paragraph}>
          If you are a new customer, you will receive an email with instructions
          to activate your account. This email will include a link to set up
          your password and log in to your account. <br /> If you are an
          existing customer, your new services or subscription updates will be
          available in your existing account.
        </p>
      </div>
      <h2 style={styles.subHeading}>3. Accessing Your Services</h2>
      <div>
        <h3 style={styles.sectionHeading}>3.1 Login to Your Account</h3>
        <p style={styles.paragraph}>
          Visit our website and click on the "Login" button. <br /> Enter your
          registered email address and password to access your account.
        </p>
      </div>
      <div>
        <h3 style={styles.sectionHeading}>3.2 Service Dashboard</h3>
        <p style={styles.paragraph}>
          Once logged in, navigate to the dashboard where you will find all the
          services you have subscribed to. From the dashboard, you can manage
          your subscriptions, access support, and utilize the features of our
          software.
        </p>
      </div>
      <h2 style={styles.subHeading}>4. Using Web-Based Services</h2>
      <div>
        <h3 style={styles.sectionHeading}>4.1 Access Through Browser</h3>
        <p style={styles.paragraph}>
          For web-based applications, simply log in to your account through your
          web browser. No downloads or installations are required. All features
          and functionalities are accessible online.
        </p>
      </div>
      <h2 style={styles.subHeading}>5. Technical Support</h2>
      <div>
        <h3 style={styles.sectionHeading}>5.1 Contact Support</h3>
        <p style={styles.paragraph}>
          If you need assistance, you can reach our support team via email at{" "}
          <Link to="mailto:support@krunk.ai" style={styles.link}>
            support@krunk.ai
          </Link>
          .
        </p>
      </div>
      <h2 style={styles.subHeading}>6. Changes to Services</h2>
      <p style={styles.paragraph}>
        We may update our services and features from time to time. You will be
        notified of any significant changes via email or through notifications
        in your account dashboard.
      </p>
      <h2 style={styles.subHeading}>7. Contact Us</h2>
      <p style={styles.paragraph}>
        For any questions regarding the access or delivery of your services,
        please contact us: <br />
        Email:{" "}
        <Link to="mailto:support@krunk.ai" style={styles.link}>
          support@krunk.ai
        </Link>{" "}
        <br />
        Address: 509/170 new tg civil lines dr bnn road, new hyderabad, Lucknow.
        <br />
        Thank you for choosing Krunk Technologies Private Limited. We are
        committed to providing you with seamless and efficient service delivery.
      </p>
    </section>
  );
};

export default ShippingPolicy;
