import React ,{useEffect,useState} from 'react';
import './Smart_secret.css';


import full_group from '../assets/smart_secret/full_group.svg';
import check from '../assets/smart_secret/tick_mark.svg';

const SmartSecret = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 786);
    handleResize();
    console.log("hey this is the size",window.innerWidth)
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const renderWebContent = () =>{
    return <div className="smartsecret-container">
      <div className="smartsecret-left">
          <div className="smartsecret-header">
            <h1>The Secret To Smarter, Effortless Support</h1>
          </div>
          
          <div className="smartsecret-subheader">
            <p>Boost engagement, save time, and reduce costs with AI features that fit your business perfectly.</p>
          </div>

          <div className="smartsecret-features">
            <ul className="feature-list">
              {features.map((feature, index) => (
                <li key={index} className="feature-item">
                  <img 
                    src={check}
                    style={{ width: "20pt", height: "20pt" }}
                    alt="check icon" 
                    className="feature-icon"
                  />
                  <span className="feature-text">{feature}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="smartsecret-buttons"  onClick={() => window.open('https://calendly.com/krunk-ai/30min?', '_blank')}
  style={{ cursor: 'pointer' }}>
            <button className="schedule-call">Schedule a Call</button>
         
          </div>
        </div>

        <div className="smartsecret-right">
          <img src={full_group}></img>
        </div>
    </div>
  }

  const rendermobileContent = () =>{
    
    return <div className="smartsecret-container">
      <div className="smartsecret-right">
        <img src={full_group}></img>
      </div>
    <div className="smartsecret-left">
        <div className="smartsecret-header">
          <h1>The Secret To Smarter, Effortless Support</h1>
        </div>
        
        <div className="smartsecret-subheader">
          <p>Boost engagement, save time, and reduce costs with AI features that fit your business perfectly.</p>
        </div>

        <div className="smartsecret-features">
          <ul className="feature-list">
            {features.map((feature, index) => (
              <li key={index} className="feature-item">
                <img 
                  src={check}
                  style={{ width: "20pt", height: "20pt" }}
                  alt="check icon" 
                  className="feature-icon"
                />
                <span className="feature-text">{feature}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="smartsecret-buttons"  >
          <button className="schedule-call" onClick={() => window.open('https://calendly.com/krunk-ai/30min?', '_blank')}
  style={{ cursor: 'pointer' }}>Schedule a Call</button>
       
        </div>
      </div>

      
  </div>
  }
  const features = [
    'Multilingual Support',
    '24x7 Availability',
    'Low Setup Costs',
    'Audience Building & Retargeting'
  ];

  return (
   <div>
    {isMobile && rendermobileContent()}
{!isMobile && renderWebContent()}
   </div>
    
        
    
   
  );
};

export default SmartSecret;



