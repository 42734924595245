import React, { useState } from 'react';
import './Header.css';
import Brand_name from '../assets/logo.svg';
import { useNavigate } from "react-router-dom";
const Header = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <header className="header">
        <div className="logo">
          {/* <img src="https://krunk.ai/krunk.ai.svg" alt="logo" width="40" height="40" /> */}
          <img src={Brand_name} alt="brand-name" width="174" height="100" onClick={handleLogoClick} />
        </div>
        <nav className="nav">
          <a href="https://youtube.com/shorts/5Liu6wE7I2c">Demo</a>
          {/* <a href="#blog">Blog</a>
          <a href="#login">Login</a> */}
          <button  onClick={() => window.open('https://calendly.com/krunk-ai/30min', '_blank')} className="schedule-button">Schedule a Call</button>
        </nav>
        <div className="menu-icon" onClick={toggleSidebar}>
          ☰
        </div>
      </header>

      {/* Sidebar for mobile */}
      <aside className={`mobile-nav ${isSidebarOpen ? 'active' : ''}`}>
        <a href="https://youtube.com/shorts/5Liu6wE7I2c" onClick={toggleSidebar}>Demo</a>
        {/* <a href="#blog" onClick={toggleSidebar}>Blog</a>
        <a href="#login" onClick={toggleSidebar}>Login</a> */}
        <button  onClick={() => window.open('https://calendly.com/krunk-ai/30min', '_blank')} className="schedule-button" >Schedule a Call</button>
      </aside>

      {/* Overlay for sidebar */}
      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
    </>
  );
};

export default Header;
