import React, { useState, useEffect } from 'react';
import './Offerings.css';

import webimage from '../assets/web.png';
import call from '../assets/call.png'

import whatsapp from "../assets/whatsapp.png"


function HeaderSection() {
  return (
    <div className="offerings">
      <h1 className="offerings-title">Our Offerings At A Glance</h1>
      <p className="offerings-subtitle">With multichannel support, we've always got you covered.</p>
    </div>
  );
}

const TabNavigation = ({ activeTab, setActiveTab }) => {
  const tabs = ['Web AI Salesbot',  'Whatsapp AI SalesAgent'];

  return (
    <div className='tab-container'>
   <div className="tab-navigation">
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`tab-item ${activeTab === tab ? 'active' : ''}`}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
    </div>
 
  );
};

const ChatPreview = () => {
  return (
    <div className="chat-preview">
            <img src={webimage} alt="Product"  />    
    </div>
  );
};

const AICall = () =>{
  return (
    <div className="chat-preview">
  
            <img src={call} alt="Product"  />
        
    </div>
  );
}

const WhatsappCall = () =>{
  return (
    <div className="chat-preview">
  
            <img src={whatsapp} alt="Product"  />
        
    </div>
  );
}
const DescriptionSectionWeb = () => {
  return (
    <div className="description-section">
      <h1>Your AI Chat Expert, Right on Your Website</h1>
      <p>Say goodbye to unanswered queries. From instant answers to personalized recommendations, your visitors will always feel supported.</p>
    </div>
  );
};
const DescriptionSectionCall = () => {
  return (
    <div className="description-section">
      <h1>Your AI-Powered Call Assistant: Professional, Instant, Reliable</h1>
      <p>Our AI Calling Bot handles inquiries, provides quick answers, and ensures your business is always reachable—without hold times or human error.</p>
    </div>
  );
};
const DescriptionSectionWhatsapp = () => {
  return (
    <div className="description-section">
      <h1>Instant Messaging, Made Smarter with Whatsapp AI Agent</h1>
      <p>Engage customers directly on WhatsApp 24/7. From order updates to product questions, our WhatsApp AI Agent has it covered.</p>
    </div>
  );
};
function Offerings() {
  const [activeTab, setActiveTab] = useState('Web AI Salesbot');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case 'Web AI Salesbot':
        return <ChatPreview />;
      case 'AI Call Handler':
        return <AICall />;
      case 'Whatsapp AI SalesAgent':
        return <WhatsappCall />;
      default:
        return null;
    }
  };
 const renderDescription = () => {
  switch (activeTab) {
    case 'Web AI Salesbot':
      return <DescriptionSectionWeb />;
    case 'AI Call Handler':
      return <DescriptionSectionCall />;
    case 'Whatsapp AI SalesAgent':
      return <DescriptionSectionWhatsapp />;
    default:
      return null;
  }
 }
  const renderWebContent = () =>{
    return <div className='fullcontainer'>
      <div className='subheading'> Web AI Salesbot</div>
      <ChatPreview></ChatPreview>
      <DescriptionSectionWeb></DescriptionSectionWeb>
      
      <div className='subheading'> Whatsapp AI SalesAgent</div>
      <WhatsappCall></WhatsappCall>
      <DescriptionSectionWhatsapp></DescriptionSectionWhatsapp>
    </div>
  }

  return (
    <div>
      <HeaderSection />
      {!isMobile   && <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
      }
     {!isMobile && renderContent()}
     {!isMobile && renderDescription()}
     {isMobile && renderWebContent() }
    </div>
  );
}

export default Offerings;
